import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="#" className="image avatar">
            <img src={avatar} alt="" />
          </a>
          <h1>
            <strong>Hallo, ich bin Caroline Kaiser</strong>, staatlich geprüfte
            Übersetzerin der spanischen Sprache.
          </h1>
        </div>
        <Footer></Footer>
      </header>
    )
  }
}

export default Header
