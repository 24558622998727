import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
  },
]

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Caroline Kaiser Übersetzungen Spanisch - Deutsch'
    const siteDescription = 'Übersetzungen Spanisch - Deutsch'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                Sie verstehen nur Spanisch? Dann kann ich Ihnen weiterhelfen!
              </h2>
            </header>
            <p>Ich biete Übersetzungen sowie Dolmetscherdienste an.</p>
            <p>
              Als staatlich geprüfte Übersetzerin der spanischen Sprache,
              öffentlich bestellt und vereidigt beim Landgericht München II.,
              übernehme ich Aufträge aller Art. Egal ob schriftliche
              Übersetzungen oder Dolmetscherdienste.
            </p>
            <p>
              Sie benötigen Hilfe bei Behörden oder beim Arzt? Sie möchten
              heiraten und auch verstehen was der Beamte sagt? Zuerst muss aber
              die spanische Geburtsurkunde ins Deutsche übersetzt werden? Bei
              all diesen Dingen und mehr kann ich Ihnen behilflich sein. Sie
              möchten mehr erfahren? Kontaktieren Sie mich und ich erstelle
              Ihnen ein auf Sie speziell abgestimmtes Angebot.
            </p>
          </section>

          <section id="two">
            <h2>Arbeitserfahrung</h2>
            <p>
              Ich arbeite seit nun 8 Jahren als freiberufliche Übersetzerin.{' '}
            </p>
            <p>
              Hauptberuflich habe ich in einem Solarunternehmen gearbeitet, und
              habe dort unsere Niederlassungen im europäischen Ausland betreut
              sowie den deutschen Vertrieb in der Auftragsabwicklung
              unterstützt. Als 2012 meine Tochter geboren wurde begann ich in
              einem anderen Unternehmen in Teilzeit zu arbeiten sowie weiterhin
              als freiberufliche Übersetzerin.
            </p>
            <p>
              Ich bin 2 sprachig aufgewachsen, meine Mutter ist Spanierin und
              mein Vater ist Deutscher. Durch meinen täglichen Kontakt zur
              spanischen Sprache war mir bereits in frühen Jahren klar, dass ich
              dies zu meinem Beruf machen möchte.
            </p>
          </section>

          <section id="three">
            <h2>Schreiben sie mir eine Nachricht</h2>
            <p>
              <ul className="labeled-icons">
                <li>
                  <h3 className="icon fa-home">
                    <span className="label">Addresse</span>
                  </h3>
                  Sinzingerstraße 26
                  <br />
                  82256 Fürstenfeldbruck
                  <br />
                  Deutschland
                </li>
                <li>
                  <h3 className="icon fa-mobile">
                    <span className="label">Telefon</span>
                  </h3>
                  0170 / 5543795
                </li>
                <li>
                  <h3 className="icon fa-envelope-o">
                    <span className="label">Email</span>
                  </h3>
                  <a href="#">carolinelischka(at)gmail.com</a>
                </li>
              </ul>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
